import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="IRStrat III"/>
            <Banner title="IRStrat III"/>
            <section className="service_details_area aspectos servicios">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">It focuses on in depth knowledge and rapid expansion of your investor base
                        and analyst coverage, through a solid institutional lobbying and broad portfolio of analysis tools, to secure more
                        resources and achieve an optimal visibility and marketability, including:</p>
                    </div>
                    <div className="row">
                       <div className="col-lg-12">
                             <div className="service_left_sidebar_old">
                                <ul className="nav nav-tabs service_tab mb_50" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="paquete1-tab" data-toggle="tab"
                                           href="#paquete1" role="tab"
                                           aria-controls="paquete1" aria-selected="true">Package 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete2-tab" data-toggle="tab" href="#paquete2"
                                           role="tab"
                                           aria-controls="paquete2" aria-selected="false">Package 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete3-tab" data-toggle="tab" href="#paquete3"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Package 3</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="paquete1" role="tabpanel"
                                     aria-labelledby="paquete1-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                 <div className="row">
                                                    <div className="col-md-6 mb-5">
                                                        <div className="" role="tab">
                                                            <h4 className="panel-title">
                                                            Investor profiling

                                                            </h4>
                                                        </div>

                                                            <div className="panel-body">


                                                                <ul className="list_style">
                                                                    <li><i className="fa fa-angle-right"></i>Execution of perception studies
                                                                    </li>
                                                                    <li><i className="fa fa-angle-right"></i>Structuring of shareholders proxy
                                                                    </li>
                                                                    <li><i className="fa fa-angle-right"></i>Preparation of briefing books on investors
                                                                    </li>
                                                                    <li><i className="fa fa-angle-right"></i>Post-earnings release surveys
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                    </div>
                                                    <div className="col-md-6 mb-5">
                                                    <div className="" role="tab">
                                                        <h4 className="panel-title">
                                                          Own events

                                                        </h4>
                                                    </div>

                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Planning and logistics for investor days
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Planning and logistics for site visits
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Planning and logistics for press conferences
                                                                </li>
                                                            </ul>
                                                        </div>

                                                </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="" role="tab">
                                                            <h4 className="panel-title">
                                                            Investor prospecting

                                                            </h4>
                                                        </div>

                                                            <div className="panel-body">

                                                                <ul className="list_style">
                                                                    <li><i className="fa fa-angle-right"></i>Buy-side investors targeting
                                                                    </li>
                                                                    <li><i className="fa fa-angle-right"></i>Identification of target segment (investment style, profile, and so on)
                                                                    </li>
                                                                    <li><i className="fa fa-angle-right"></i>Consultancy on road shows and non-deal road shows
                                                                    </li>
                                                                    <li><i className="fa fa-angle-right"></i>Presentations to stock promoters
                                                                    </li>
                                                                    <li><i className="fa fa-angle-right"></i>Distribution lists management (creation and development)
                                                                    </li>
                                                                    <li><i className="fa fa-angle-right"></i>Presentations and events with pension and investment funds
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                    </div>

                                                    <div className="col-md-6">
                                                    <div  role="tab">
                                                        <h4 className="panel-title">
                                                          Analyst coverage

                                                        </h4>
                                                    </div>

                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Valuation models development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Identification of the target segment (investment style, profile, and so on)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Sell-side investors targeting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations to analysis departments
                                                                </li>
                                                            </ul>
                                                        </div>

                                                </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete2" role="tabpanel"
                                     aria-labelledby="paquete2-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p2_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group ">
                                                <div className="row">
                                                    <div className="col-md-6 mb-5">
                                                    <div className="" role="tab">
                                                        <h4 className="panel-title">
                                                            Media strategy

                                                        </h4>
                                                    </div>
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Procurement of slots with specialized financial media in Mexico and
                                                                    US (print, radio and TV)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Press conferences planning and execution

                                                                </li>
                                                            </ul>
                                                    </div>
                                                </div>
                                                    <div className="col-md-6 mb-5">
                                                    <div className="" role="tab">
                                                        <h4 className="panel-title">
                                                            Media monitoring

                                                        </h4>
                                                    </div>
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Intelligence on analyst reports

                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Intelligence on social networks (Twitter and Linkedin)

                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Intelligence on specialized blogs

                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Intelligence on media
                                                                </li>
                                                            </ul>
                                                        </div>

                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-5">
                                                    <div className="" role="tab">
                                                        <h4 className="panel-title">
                                                            Benchmarking
                                                        </h4>
                                                    </div>

                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Comparative annual results (industry, market and region)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative quarterly results (industry, market and region)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative market sentiment (positioning and visibility)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative IR programs
                                                                </li>
                                                            </ul>
                                                        </div>

                                                </div>
                                                    <div className="col-md-6 mb-5">
                                                    <div className="" role="tab">
                                                        <h4 className="panel-title">
                                                           Regulatory framework
                                                        </h4>
                                                    </div>

                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Timely information on regulatory changes (CNBV, CONSAR, BMV, BIVA and SEC)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on implementation (XBLR, insider trading, and so on)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Structuring corporate functions
                                                                </li>
                                                            </ul>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete3" role="tabpanel"
                                     aria-labelledby="paquete3-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p3_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                 <div className="row">
                                                <div className="col-md-4">
                                                    <div className="" role="tab">
                                                        <h4 className="panel-title">
                                                            Consultancy on trading

                                                        </h4>
                                                    </div>
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on treasury transactions (share buybacks, mirror funds, and so on)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on market makers operations
                                                                </li>
                                                            </ul>

                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="" role="tab">
                                                        <h4 className="panel-title">
                                                            Strategic agenda

                                                        </h4>
                                                    </div>
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>National and international financial events (summits, roundtables, forums, and so on)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Participative lobbying (we seek visibility)
                                                                </li>
                                                            </ul>
                                                        </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="" role="tab">
                                                        <h4 className="panel-title">
                                                            IR Edge 3.0 services
                                                        </h4>
                                                    </div>
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>IREdge terminal
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Intelligence interface
                                                                </li>
                                                            </ul>

                                                    </div>
                                                </div>
</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
